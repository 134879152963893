angular.module('fingerink')

    .directive('templateRow', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: 'web/main/templates/directives/templateRow.tpl.html',
            scope: {
                documento: '=',
                full: '=',
                update: '&'
            },
            controllerAs: 'controller',
            controller: 'templateRowCtrl'
        };
    })
    .controller('templateRowCtrl', function ($rootScope, $scope, templateService, $state, $translate, viewTemplateModal, swalService) {
        var that = this;

        that.documento = $scope.documento;
        that.full = $scope.full;
        templateService.getURL(that.documento.id, 0).then(response => that.urlImage = response.data);
        that.go = () => $state.go('main.send', { template: that.documento.id });
        that.viewDocument = documento => viewTemplateModal.openModal(null, documento);

        that.deleteDocument = documento =>
            swalService.requestSwal("Eliminar documento", 'El documento se enviará a la papelera. ¿Estás seguro de que quieres realizar esta acción?', 'warning', () => templateService.deleteTemplate(documento.id))
                .then(response => {
                    swal($translate.instant("¡Correcto!"), $translate.instant("Se ha eliminado el documento correctamente"), "success");
                    $scope.update();
                }, response => {
                    swal($translate.instant('Algo ha ido mal'), $translate.instant("Algo ha ido mal eliminando el documento"), "error");
                    $scope.update();
                });
    });
